<template>
  <common-trs-card class="px-4">
    <v-card-text class="py-0">
      <div class="d-flex">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="8"
            lg="8"
          >
            <common-common-info-item
              :label="'Name'"
              class="mb-10"
            >
              <slot
                name="name"
              >
                <span class="text-h4 font-weight-bold text-capitalize">{{ selectedUserProfile.first_name + ' ' + selectedUserProfile.last_name }}</span>
              </slot>
            </common-common-info-item>
            <common-common-info-item
              :label="'Email Address'"
              class="mb-10"
            >
              <slot
                name="username"
              >
                <span class="text-h4 font-weight-bold">{{ selectedUserProfile.username }}</span>
              </slot>
            </common-common-info-item>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </common-trs-card>
</template>

<script>
  export default {
    name: 'UserProfile',

    computed: {
      selectedUserProfile () {
        return this.$store.getters['access/selectedUser']
      },
    },
  }
</script>
